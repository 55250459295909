<template>
  <div class="relative">
    <ZnInput
      :value="searchValue"
      class="my-2"
      classes="placeholder:text-sm"
      placeholder="Search by keyword"
      @input="updateSearchValue"
    />
    <ZenniIconsIconClose
      v-if="searchValue"
      size="sm"
      class="text-grays-darkest absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
      @click="clearSearch"
    />
    <ZenniIconsIconSearch
      v-else
      size="xs"
      class="text-grays-dark absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
})

const emit = defineEmits([
  'update:modelValue',
  'performSearch',
  'debouncePerformSearch',
])

const searchValue = computed({
  get: () => props.modelValue,
  set: (value: string) => emit('update:modelValue', value),
})

const updateSearchValue = (val: string) => {
  emit('debouncePerformSearch', val)
  searchValue.value = val
}

const clearSearch = () => {
  searchValue.value = ''
  emit('performSearch')
}
</script>
