<template>
  <div
    class="relative flex cursor-pointer items-center space-x-2"
    @click="showInfoModal = true"
  >
    <template v-if="giftAmount > 0">
      <span class="text-purples-dark font-semibold"
        >Credit left: {{ formattedBalance }}</span
      >
      <AppHeaderCreditTooltip :credit="formattedBalance" />
      <ZenniIconsIconInformationCircle
        class="stroke-purples-dark [&>path]:fill-purples-dark fill-none [&>path]:stroke-none"
        size="base"
      />
      <ZnModal
        size="small"
        :show-close-icon="false"
        :show="showInfoModal"
        @close="showInfoModal = false"
      >
        <template #title>
          <h1 class="text-lg font-semibold">How credit works</h1>
        </template>
        <template #body>
          <ul class="mt-5 list-disc space-y-5 px-6">
            <li>
              Credit is automatically applied to your account and works like a
              gift card - feel free to apply it across multiple purchases.
            </li>
            <li>
              Credit can be applied to all products from our current catalog,
              including taxes and shipping.
            </li>
            <li>You're responsible for any costs over your credit amount.</li>
            <li>
              Credit must be used by
              <span class="font-semibold">{{ giftEndDate }}</span> and does not
              carry over or transfer.
            </li>
          </ul>
          <div class="mt-5 flex">
            <ZnButton
              class="w-full text-center"
              type="secondary"
              @click="showInfoModal = false"
              >Close</ZnButton
            >
          </div>
        </template>
      </ZnModal>
    </template>
  </div>
</template>

<script setup lang="ts">
import { format, parseISO } from 'date-fns'
import { storeToRefs } from 'pinia'
import { useGuestStore } from '~/store/guest'
import { formatCurrency } from '@/utils/helper/formatCurrency'
import { ref, computed } from '#imports'
import { useCart } from '~/composables/useCart'

const { gift: userGift, giftAmount } = storeToRefs(useGuestStore())
const { availableCredits } = useCart()

const showInfoModal = ref<boolean>(false)

const formattedBalance = computed<string>(() => {
  // Show $0.00 if there is gift amount but current cart is using all credits
  return formatCurrency(
    giftAmount.value > 0 ? availableCredits.value : 0,
    'en-US',
    {
      currency: 'USD',
      minimumFractionDigits: 2,
    },
  )
})

const giftEndDate = computed<string>(() => {
  const { to } = userGift.value?.schedule?.date ?? {}
  if (to) {
    return format(parseISO(to), 'MM/dd/yyyy')
  }
  return '--'
})
</script>
