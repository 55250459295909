<template>
  <div
    class="text-teal-primary border-grays-light flex w-full flex-col flex-wrap justify-between gap-6 border-t bg-white px-10 py-4 text-sm font-semibold leading-[22px] sm:flex-row md:px-20"
  >
    <div class="flex flex-col gap-6 sm:flex-row sm:gap-10">
      <ul class="flex w-fit flex-wrap gap-6 sm:gap-10">
        <li>
          <nuxt-link
            to="/terms-of-use/employee"
            target="_blank"
            class="underline underline-offset-2"
            >Terms of Use</nuxt-link
          >
        </li>
        <li>
          <nuxt-link
            to="https://www.zennioptical.com/privacy-policy"
            target="_blank"
            class="underline-offset–2 underline"
            >Privacy Policy</nuxt-link
          >
        </li>
      </ul>
    </div>
    <span>
      Powered by
      <nuxt-link class="underline" to="https://zenni.io" target="_blank">
        zenni.io
      </nuxt-link>
    </span>
  </div>
</template>
