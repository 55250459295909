<template>
  <!-- the min-h-[100vh] is needed when scrolling for making the header sticky. -->
  <div
    class="flex flex-col overflow-visible"
    :class="scrollView ? 'min-h-[100vh]' : 'h-[100vh]'"
  >
    <div ref="obsTracker" class="h-1 w-full" />
    <Header :scroll-view="scrollView" />
    <main class="relative flex flex-1 flex-col">
      <NuxtLoadingIndicator />
      <slot />
    </main>
    <Footer />
  </div>
</template>

<script setup lang="ts">
import { useIntersectionObserver } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { ref, useLazyAsyncData, useNuxtApp, useCookie } from '#imports'
import { useGuestCartStore } from '~/store/guestCart'
import { useGuestStore } from '~/store/guest'

const guestCartStore = useGuestCartStore()
const { guest } = storeToRefs(useGuestStore())

const guestCartCookie = useCookie<{ cartId: string }>('guestCart')

const nuxtApp = useNuxtApp()
useLazyAsyncData(
  'cart',
  async () => {
    const decodedGuestCartCookie =
      typeof guestCartCookie.value === 'string'
        ? JSON.parse(guestCartCookie.value)
        : guestCartCookie.value
    if (guest.value.token || guest.value.loggedInToken) {
      const needsCart =
        !guestCartCookie.value ||
        (guestCartCookie.value && !decodedGuestCartCookie.cartId)

      if (guest.value && guest.value.storeId) {
        if (needsCart) {
          await guestCartStore.createCart()
        }
        nuxtApp.runWithContext(() => guestCartStore.loadCart())
      }
    }
  },
  {
    immediate: true,
    watch: [guest.value],
  },
)

const obsTracker = ref<HTMLElement>()
const scrollView = ref(false)
useIntersectionObserver(
  obsTracker,
  ([{ isIntersecting }]) => {
    scrollView.value = !isIntersecting
  },
  {
    rootMargin: '22px',
    threshold: 0.5,
  },
)
</script>
